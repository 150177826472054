
import { MESSAGE,PROFILE,ADVERTISER,
  DISTRIBUTOR,
  RIGHTS,
  ROLE,
  LOGGEDIN, 
  MESSAGEOTP,
  MESSAGE2,
  PRODUCER,
  MESSAGEOTPVERIFY,
  SUBADMIN} from '../constants/actionTypes';
import * as api from '../api/index.js';

  

export const login = (formData,navigate) => async (dispatch) => {
  
    try {
      
      const { data } = await api.login(formData);
      if(data?.statuscode==200)
      {
        
        dispatch({ type: PROFILE, payload:data?.id });
        dispatch({ type: ADVERTISER, payload:data?.advertiser[0] });
        dispatch({ type: PRODUCER, payload:data?.producer[0] });

        dispatch({ type: DISTRIBUTOR, payload:data?.distributor[0] });
        dispatch({ type: RIGHTS, payload:data?.Rights[0] });
        dispatch({ type: ROLE, payload:data?.id?.userType?.roleName });
        dispatch({ type: LOGGEDIN, payload:true });
        // localStorage.setItem("profile", JSON.stringify(data?.id));
        // localStorage.setItem("advertiser", JSON.stringify(data?.advertiser[0]));
        // localStorage.setItem("distributor", JSON.stringify(data?.distributor[0]));
        // localStorage.setItem("rights", JSON.stringify(data?.Rights[0]));
        // localStorage.setItem("role", JSON.stringify(data?.id?.userType?.roleName));
        // localStorage.setItem("loggedIn", "true");
        localStorage.setItem("loggedInDetails",JSON.stringify(data))
        localStorage.setItem("remember_me",formData?.remember_me)
        localStorage.setItem("loginDetails",JSON.stringify(formData))
        // window.location.reload(false)
        // console.log(data,"lplp")
       if(data?.is_login_first_time==false)
       {
       data.advertiser != "" ? navigate("/AdForm/AdForm/",{state:{forceShow:true}}) :
         navigate("/Dashboard",{state:{forceShow:true}})}
        else
        navigate("/Authentication/ChangePassword",{state:{forceShow:true}})
      }
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const forgot_password = (formData) => async (dispatch) => {
    try {
      const { data } = await api.forgot_password(formData);
      dispatch({ type: MESSAGEOTP, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  
  export const change_password = (formData,navigate) => async (dispatch) => {
    try {
      const { data } = await api.change_password(formData);
      dispatch({ type: MESSAGE, payload:data });
      localStorage.setItem("remember_me", "false")
              localStorage.setItem("loginDetails","{}")
              localStorage.setItem("loggedInDetails","{}")
        dispatch({ type: PROFILE, payload:{} });
        dispatch({ type: ADVERTISER, payload:{} });
        dispatch({ type: DISTRIBUTOR, payload:{} });
        // dispatch({type:SUBADMIN,payload:{}})
        dispatch({ type: RIGHTS, payload:{} });
        dispatch({ type: ROLE, payload:'' });
        dispatch({ type: LOGGEDIN, payload:false });
          
      navigate("/",{state:{forceShow:true}})
   
    } catch (error) {
      console.log(error);
    }
  };

  export const verify_otp = (formData) => async (dispatch) => {
    try {
      const { data } = await api.verify_otp(formData);
      dispatch({ type: MESSAGEOTPVERIFY, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const update_password = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_password(formData);
      dispatch({ type: MESSAGE2, payload: data });
    } catch (error) {
      console.log(error)
    }
  }